<script setup lang="ts">
import {useEditor} from "~/stores/editor";
import ColoringCard from "~/components/ColoringCard.vue";

const route = useRoute()
const {itemClass} = defineProps({
  itemClass: {
    type: String,
    default: "w-2/5 md:w-1/4 flex-none px-2"
  },
  wrapClass: {
    type: String,
    default: "flex-nowrap"
  }
})
const editorStore = useEditor()

const workspaces = computed(() => editorStore.workspaces)
editorStore.loadCurrentWork()
</script>

<template>
  <div class="overflow-auto no-scrollbar px-2">
    <div class="flex" :class="wrapClass">
      <div v-for="item in workspaces" :key="item.id" :class="itemClass">
        <coloring-card :value="item" class="block" is-draw/>
      </div>
      <div :class="itemClass">
        <nuxt-link
          to="/editor?id=new"
          class="card group"
        >
          <div class="pt-full relative bg-white">
            <div class="absolute inset-2 flex items-center justify-center">
              <div class="w-12 h-12 i-con-plus text-gray-300"></div>
            </div>
          </div>
          <div class="card-head group-hover:translate-y-0">
            <div class="flex gap-1 items-center">
              <div class="font-semibold hover:underline whitespace-nowrap truncate">Add new</div>
            </div>
          </div>
        </nuxt-link>
      </div>
      <div class="flex-none w-2"/>
    </div>
  </div>
</template>
